<template>
  <div class="bg-gray-100 px-6">
    <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
        <div class="px-12 py-6">
            <h1
            class="text-blue-700 text-2xl font-normal sm:text-4xl text-left">
              <faIcon
              icon="money-bill-alt"
              type="fas"
              class="text-green-500 text-3xl"
              >
              </faIcon>
              Lista de abonos
            </h1>
            <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Aquí tienes un historial de los abonos.</p>
        </div>
    </div>
    <ListTable
      :columns="fields"
      :filters="filters"
      rowsGetter="abono/abonos"
      :buttons="buttons"
      :managementButtons="true"
      :useStorage="true"
      :columnFilters="true"
      @anular-abono="abrirModalConfirmacionAnular"
      @filter-input="setFilters"
      @dates-input="setDates"
    >
    <template v-slot:montoAbono="data">
      <p class="text-gray-800 break-words font-medium text-xs 2xl:text-base">
        {{ `$${formatearCifra(data.value)}` }}
      </p>
    </template>

    </ListTable>
    <Pagination
      path=""
      :meta="meta"
      :links="links"
      action="abono/paginateAbonos"
      v-if="meta && meta.last_page > 1"
    />
    
    <Modal 
      v-if="esVisibleConfirmacionAnular"
      :titulo="'¡Un momento!'"
      :parrafo="`Vas a anular el abono nº ${idAbonoSeleccionado}. La anulación de un abono es un proceso irreversible.`"
      :pregunta="'¿Deseas continuar?'"
      :textoBotonAction="'Sí, anular'"
      :colorBotonAccion="'bg-red-700 hover:bg-red-600'"
      :inhabilitacion="estaInhabilitadoBotonAnular"
      @cerrar="cerrarModalConfirmacionAnular"
      @accion="anularAbono(idAbonoSeleccionado)"
      class="z-40"
    >
    </Modal>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable";
import Pagination from "@/components/Pagination";
import Modal from "@/components/Modal";

import { separarPorMiles } from '@/utils/helpers';
import { mapGetters } from "vuex";

import store from "@/store/index";
import AbonoService from "@/services/AbonoService";


export default {
    name: "ListaAbonos",
    setup() {
      let initials = store.getters['abono/filters']
      let dates = store.getters['abono/dates']
      return {
        filters: [
          {
            "placeholder": "N°",
            "type": "number",
            "field": "ab_id",
            "initial": initials && initials.ab_id ? initials.ab_id.value : ""
          },
          {
            "placeholder": "N° OT",
            "type": "number",
            "field": "ot_num",
            "initial": initials && initials.ot_num ? initials.ot_num.value : ""
          },
          {
            "type": "none",
          },
          {
            "placeholder": "Por M. Pago",
            "type": "select",
            "field": "ab_metodo_pago",
            "options": [{value: "Transbank", text: "Transbank"},{value: "Cheque", text: "Cheque"}, {value: "Efectivo", text: "Efectivo"}],
            "initial": initials && initials.ab_metodo_pago ? initials.ab_metodo_pago.value : ""
          },
          {
            "placeholder": "Por fecha",
            "field": "created_at",
            "type": "date-range",
            "initial": dates ? dates : null
          },
          {
            "type": "none",
          }
        ],
      }
    },
    data() {
        return {
        fields: {
            'idAbono' : 'Abono',
            'numeroOT' : 'nº OT',
            'montoAbono' : 'monto',
            'medioPago' : 'm. Pago',
            'fecha' : 'fecha',
            'hora' : 'hora',
        },
        rows: [
        ],
        esVisibleConfirmacionAnular: false,
        estaInhabilitadoBotonAnular: false,
        estadoSeleccionado: null,
        idAbonoSeleccionado: null
        }
    },
    computed: {
    ...mapGetters("abono", ["error", "meta", "links"]),
    buttons() {
      let buttons = [
        {
          "text": "Anular",
          "event": "anular-abono"
        }
      ]
      return buttons
    }
    },
    methods: {
      abrirModalConfirmacionAnular(fila) {
          this.idAbonoSeleccionado = fila.idAbono;
          this.esVisibleConfirmacionAnular = true;
      },
      cerrarModalConfirmacionAnular() {
        this.esVisibleConfirmacionAnular = false;
        this.estaInhabilitadoBotonAnular = false;
      },
      cargarListaAbonos(){
        store.dispatch("abono/getAbonos", 1);
      },
      anularAbono(idAbono) {
        this.estaInhabilitadoBotonAnular = true;
        AbonoService.nullateAbono(idAbono)
        .then(() => {
          this.cargarListaAbonos();
          this.cerrarModalConfirmacionAnular();
        })
        .then(() => {
          this.emitter.emit("notification-set", {
              type: 'success',
              title: '¡Todo en orden!',
              text: `Has anulado el abono nº ${this.idAbonoSeleccionado}.`,
              time: 10000,
              group: 'notifications'
            });
        })
        .catch(() => {
          this.cerrarModalConfirmacionAnular();
          this.emitter.emit("notification-set", {
              type: 'error',
              title: '¡Un momento!',
              text: 'Ha ocurrido un imprevisto. Inténtalo de nuevo.',
              time: 10000,
              group: 'notifications'
            });
        });
      },
      setDates(dates) {
        store.dispatch("abono/setDates", dates);
        store.dispatch("abono/getAbonos", 1);
      },
      setFilters(filters) {
        store.dispatch("abono/setFilters", filters);
        store.dispatch("abono/getAbonos", 1);
      },
      formatearCifra(cifra) {
        return separarPorMiles(cifra);
      }
    },
    beforeRouteEnter(to, from, next) {
        const currentPage = parseInt(to.query.page) || 1
        store.dispatch("abono/getAbonos", currentPage).then(() => {
          to.params.page = currentPage
          next()
        })
    },
    components: {
        ListTable,
        Modal,
        Pagination,
    }
}
</script>